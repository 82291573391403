<!-- 选择头像框 -->
<template>
    <div class="mob-emperor-main">
        <img :src="imgUrl('mob-emperor-light')" class="mob-emperor-light">
        <div class="box-div">
            <img :src="imgUrl('mob-emperor-title')" class="mob-emperor-title">
            <div class="mob-emperor-content">
                <img :src="imgUrl('mob-emperor-open-bg')" class="mob-emperor-open-bg">
                <input class="mob-userNoInput" placeholder="请输入天狼数字ID" v-model="showUserInfo" />
                <input type="tel" class="mob-userPhoneInput" placeholder="请输此ID绑定的手机号码" v-model="userPhone" />
                <div class="mob-checkUserInfoBtn" @click="handleClickConfirmBtn">确认</div>
                <div v-if="leftDay!=0" class="mob-leftDay">{{leftDayNotice}} <span
                        class="mob-height-light">剩余{{leftDay}}天</span></div>
                <img :src="getEmperorImg()" class="mob-emperor-detail-icon">
            </div>
            <div class="mob-emperor-detail">
                <div class="mob-detail-top">{{topDescribe}}</div>
                <div class="mob-detail-mid" v-html="midDescribe" :class="{'small-font':midDescribeClass}"></div>
                <div class="mob-recharge-btn">
                    <img v-if="rechargeImg!=0" :src="imgUrl(`emperor-${rechargeImg}`)" class="mob-recharge-img"
                        @click="handleClickRechargeEmperor(rechargeImg)">
                </div>
                <div class="mob-detail-bottom">贵族权益详情请查看app内贵族页面</div>
            </div>
            <img :src="imgUrl(`${emperorStateImg}`)" class="mob-emperor-detail-img">
        </div>
    </div>
</template>


<script>
    import {
        godEmperorInfo,
        getUserios,
        successShow
    } from "./../../apis/officialWeb";
    import {
        getParam
    } from "./../../utils/utils";
    export default {
        data() {
            return {
                // userNo: "7345747",
                // userPhone: "99109923",
                // userNo: "6666903",
                // userPhone: "13522202300",
                // userNo: "46",
                // userPhone: "139410317343123",
                showUserInfo: "",
                userNo: "",
                userPhone: "",
                topDescribe: "开通神皇贵族",
                midDescribe: "超多权益等你解锁",
                nobleLevel: 0,
                nobleLight: 0,
                leftDay: 0,
                leftDayNotice: "",
                nobleEndTime: "",
                godCacheEndTime: "",
                godCacheLeftDay:"",
                nobleLeftDay:"",
                rechargeImg: "",
                midDescribeClass: false, //不可开通字体适配
                payGodEmperorState: 0, //支付记录
                emperorStateImg: "emperor-open",
            };
        },
        watch: {},
        //生命周期 - 创建完成（访问当前this实例）
        created() {

        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            let out_trade_no = getParam("out_trade_no");
            let _this = this;
            if (out_trade_no) {
                successShow({
                    outTradeNo: out_trade_no
                }).then(res => {
                    // console.log(res);
                    if (res.data.isFirstShow == 1) {
                        _this.showUserInfo = res.data.userNo;
                        _this.userPhone = res.data.phone;
                        _this.handleClickConfirmBtn();
                    }
                })
            }
        },
        components: {

        },
        methods: {
            handleClickRechargeEmperor: function (type) {
                if (this.$parent.in_weixin) {
                    //微信浏览器内打开 指引外部浏览器
                    this.$parent.open_notice = true;
                    return;
                }
                //16购买  17续费
                let index = 0;
                if (type == "4998") {
                    index = 17;
                } else if (type == "19998") {
                    index = 16;
                } else {
                    return;
                }
                getUserios({
                    userNo: this.userNo,
                    type: index
                }).then(response => {
                    // console.log(response);
                    if (response.sign == "0") {
                        let div = document.createElement("div");
                        let msg =
                            response.msg.split("/alipay/")[0] +
                            "https://werewolf.53site.com/alipay/" +
                            response.msg.split("/alipay/")[1];
                        div.innerHTML = msg;
                        document.body.appendChild(div);
                        document.forms[0].submit();
                    } else {
                        window.alert(response.msg);
                    }
                });
            },
            handleClickCloseBtn: function () {
                this.$parent.showPcOpeningEmperor = false;
            },
            getEmperorImg: function () {
                switch (this.nobleLevel) {
                    case 6: //黑皇
                        return this.imgUrl(`emperor-black${this.nobleLight ? '-n' : '-b'}`);
                    case 7: //紫皇
                        return this.imgUrl(`emperor-purple${this.nobleLight ? '-n' : '-b'}`);
                    case 8: //金皇
                        return this.imgUrl(`emperor-gold${this.nobleLight ? '-n' : '-b'}`);
                    default:
                        return this.imgUrl('emperor-black-b');
                }
            },
            handleClickConfirmBtn: function () {
                if (!this.showUserInfo || !this.userPhone) {
                    return;
                }
                var isnum = /^\d+$/.test(this.showUserInfo);
                if (!isnum) {
                    return;
                }
                this.userNo = this.showUserInfo;
                let _this = this;
                //调接口查询贵族信息
                godEmperorInfo({
                    userNo: this.userNo,
                    phone: this.userPhone
                }).then(res => {
                    // console.log(res);
                    // console.log(res.data);
                    if (res.code == 1) {
                        _this.showUserInfo = res.data.nickname;
                        _this.nobleLevel = res.data.nobleLevel;
                        _this.nobleLight = res.data.nobleLight;
                        _this.nobleEndTime = res.data.nobleEndTime;
                        _this.godCacheEndTime = res.data.godCacheEndTime;
                        _this.payGodEmperorState = res.data.payGodEmperorState;
                        _this.nobleLeftDay = res.data.nobleLeftDay;
                        _this.godCacheLeftDay = res.data.godCacheLeftDay;
                        _this.initNobleLevel();
                    } else {
                        window.alert(res.msg);
                    }
                })
            },
            getDaysBetween(dateString) {
                let curDate = new Date();
                let startDate = Date.parse(curDate);
                let endDate = Date.parse(dateString);
                let days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
                if (days < 0) {
                    days = 0;
                }
                return Math.ceil(days);
            },
            initNobleLevel() {
                // console.log('this.nobleLevel=>', this.nobleLevel);
                // this.topDescribe = 
                if (this.nobleLevel > 5) {
                    this.topDescribe = "续费神皇贵族"
                    if (this.nobleLight) {
                        this.leftDayNotice = "神皇贵族特权";
                        this.leftDay = this.nobleLeftDay;

                    } else {
                        this.leftDayNotice = "神皇贵族缓冲期";
                        this.leftDay = this.godCacheLeftDay;
                    }

                    if (this.payGodEmperorState == 1) {
                        this.midDescribeClass = false;
                        //刚激活神皇，二次进入页面，等待神皇贵族开通中
                        this.midDescribe = "等待神皇贵族开通中...";
                    } else {
                        this.emperorStateImg = "emperor-renew-2";
                        this.midDescribe = "";
                        //显示续费按钮
                        this.rechargeImg = "4998";
                    }

                } else {
                    this.leftDayNotice = "";
                    this.leftDay = 0;
                    this.emperorStateImg = "emperor-open";
                    if (this.nobleLevel == 5) {
                        this.topDescribe = "开通神皇贵族";
                        if (this.payGodEmperorState == 1) {
                            this.midDescribeClass = false;
                            //刚激活神皇，二次进入页面，等待神皇贵族开通中
                            this.midDescribe = "等待神皇贵族开通中...";
                            this.rechargeImg = 0;
                        } else {
                            //显示充值按钮
                            this.midDescribe = "";
                            this.rechargeImg = "19998";
                        }

                    } else {
                        this.rechargeImg = 0;
                        this.topDescribe = "很抱歉"
                        this.midDescribe = "您的ID尚未达到帝王贵族，</br>尚不满足开通神皇贵族的条件";
                        this.midDescribeClass = true;
                    }
                }
            }
        }
    };
</script>
<style scoped lang='scss'>
    @import "../../style/main.scss";

    .mob-emperor-main {
        background-color: #1d1b1c;
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        min-height: 177.8vw;
        z-index: 12;
    }

    .box-div {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .mob-emperor-light {
        width: 100vw;
        height: 100vw;
        position: absolute;
    }

    .mob-emperor-title {
        width: 52.7vw;
        height: 20.2vw;
        margin-left: 23.7vw;
    }

    .mob-emperor-content {
        width: 100vw;
        height: 48.3vw;
    }

    .mob-emperor-open-bg {
        width: 85.7vw;
        height: 48.3vw;
        position: absolute;
        left: 0;
    }

    .mob-userNoInput {
        width: 65.4vw;
        height: 11.4vw;
        border-radius: 2.4vw;
        border: none;
        background-color: #2D2922;
        color: white;
        position: absolute;
        margin-top: 4.6vw;
        margin-left: 5.6vw;
        outline: none;
        font-size: 3.9vw;
        text-align: center;
    }

    .mob-userPhoneInput {
        width: 65.4vw;
        height: 11.4vw;
        border-radius: 2.4vw;
        border: none;
        background-color: #2D2922;
        color: white;
        position: absolute;
        margin-top: 18.4vw;
        margin-left: 5.6vw;
        outline: none;
        font-size: 3.9vw;
        text-align: center;
    }

    .mob-checkUserInfoBtn {
        width: 65.4vw;
        height: 11.4vw;
        border-radius: 2.4vw;
        background-color: #A88B61;
        text-align: center;
        line-height: 11.4vw;
        font-size: 3.9vw;
        color: white;
        position: absolute;
        margin-top: 32.3vw;
        margin-left: 5.6vw;
    }

    .mob-emperor-detail {
        width: 95vw;
        height: 25.8vw;
        margin-left: 2.5vw;
        @include imgbg(imgUrl("mob-emperor-detail-bg.png"));
        color: #E2C9A4;
    }

    .mob-detail-top {
        width: 95vw;
        font-size: 3.9vw;
        text-align: center;
        position: absolute;
        margin-top: -1.8vw;
        line-height: 3.9vw;
        font-weight: 400;
    }

    .mob-detail-mid {
        width: 95vw;
        font-size: 6.8vw;
        text-align: center;
        position: absolute;
        margin-top: 8.4vw;
        line-height: 6.8vw;
        font-weight: 400;
        color: #FFC04F;
    }

    .mob-recharge-btn {
        width: 95vw;
        position: absolute;
        margin-top: 5vw;
        text-align: center;
    }

    .mob-recharge-img {
        width: 70vw;
    }

    .mob-detail-bottom {
        width: 95vw;
        font-size: 3.9vw;
        text-align: center;
        position: absolute;
        margin-top: 19vw;
        line-height: 3.9vw;
        font-weight: 400;
    }

    .mob-emperor-detail-img {
        width: 95vw;
        height: 64.4vw;
        margin-left: 2.5vw;
    }

    .mob-leftDay {
        width: 26.1vw;
        padding-left: 2.5vw;
        height: 10.5vw;
        border-radius: 2.4vw 0 0 2.4vw;
        background-color: #373737;
        right: 0;
        margin-top: 36.9vw;
        position: absolute;
        font-size: 3.1vw;
        line-height: 4.1vw;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 500;
    }

    .mob-height-light {
        font-size: 4.4vw;
        color: #FFC04F;
        line-height: 5.4vw;
    }

    .mob-emperor-detail-icon {
        width: 23.6vw;
        height: 24.1vw;
        position: absolute;
        right: 2.5vw;
        margin-top: 12.2vw;
    }

    .small-font {
        font-size: 3.9vw;
        margin-top: 4vw;
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }

    input::placeholder {
        color: #b19369 !important;
    }

    input {
        -webkit-user-select: auto !important;
        -webkit-appearance: none !important;
    }
</style>